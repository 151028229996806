import { graphql } from 'gatsby'
import  { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Layout from '../components/layout'

export default ({ data }) => {
  const { frontmatter, body } = data.mdx
  return (
    <Layout>
      <h1>{frontmatter.title}</h1>
      <strong>{frontmatter.date}</strong>
      <hr/>
      <br/>
      <MDXRenderer>{body}</MDXRenderer>
      <hr/>
    </Layout>
  );
};
export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
      }
      rawBody
    }
  }
`
