import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/logo.png'

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <Link to="/">
        <img src={logo} height="80px" alt={siteTitle}/>
      </Link>      
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
